<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header border-0 p-2">
        <h2 class="card-title align-items-start pr-0 mr-0">
          <span class="card-label font-weight-bolder text-dark pr-0 mr-0 mx-4 my-4">
            ÖDEV ÜNİTE-KONU RAPORU
          </span>
        </h2>
        <div class="card-toolbar">
          <v-dialog persistent v-model="filterDialog" max-width="300px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small class="mx-2" v-bind="attrs" v-on="on"> FİLTRE</v-btn>
            </template>
            <v-card>
              <v-toolbar flat>
                <v-row class="justify-content-between">
                  <v-col class="col-4">
                    <v-icon @click="filterDialog = false">mdi-close</v-icon>
                  </v-col>
                  <v-col class="col-4 d-flex justify-content-center"><h5>FİLTRELE</h5></v-col>
                  <v-col class="col-4 text-right">
                    <v-icon @click="cleanFilter">mdi-refresh</v-icon>
                  </v-col>
                </v-row>
              </v-toolbar>
              <v-card-text>
                <v-autocomplete
                    v-model="lessonIds"
                    :item-text="(item) => item.Name"
                    :item-value="(item) => item.Id"
                    :items="lessons"
                    label="Ders"
                    single-line
                    hide-details
                    clearable
                    multiple
                    @change="lessonOnChange()"
                >
                </v-autocomplete>
                <v-autocomplete
                    v-model="lessonUnitIds"
                    :item-text="(item) => item.LessonUnitName"
                    :item-value="(item) => item.LessonUnitId"
                    :items="sourceTestAchievements"
                    single-line
                    hide-details
                    clearable
                    multiple
                    @change="lessonUnitOnChange()"
                    :disabled="lessonIds.length <= 0"
                    :label="lessonIds.length > 0 ? 'Ünite' : 'Ünite (ders seçiniz)'"
                >
                </v-autocomplete>
                <v-autocomplete
                    v-model="subjectIds"
                    :item-text="(item) => item.ParentSubjectName"
                    :item-value="(item) => item.ParentSubjectId"
                    :items="subjects"
                    single-line
                    hide-details
                    clearable
                    multiple
                    @change="subjectOnChange()"
                    :disabled="lessonUnitIds.length <= 0"
                    :label="lessonUnitIds.length > 0 ? 'Konu' : 'Konu (ünite seçiniz)'"
                >
                </v-autocomplete>
                <v-autocomplete
                    v-model="childSubjectIds"
                    :item-text="(item) => item.Name"
                    :item-value="(item) => item.Id"
                    :items="childSubjects"
                    single-line
                    hide-details
                    clearable
                    multiple
                    :disabled="subjectIds.length <= 0"
                    :label="subjectIds.length > 0 ? 'Alt konu' : 'Alt konu (Konu seçiniz)'"
                >
                </v-autocomplete>
                <v-autocomplete
                    v-model="hwAttendanceStatusId"
                    :item-text="(item) => item.Name"
                    :item-value="(item) => item.Id"
                    :items="hwAttendanceStatuses"
                    label="Ödev Durumu"
                    single-line
                    hide-details
                >
                </v-autocomplete>
              </v-card-text>
              <v-card-actions class="justify-content-center">
                <v-btn
                    @click="
                    filterDialog = false
                    getData()
                  "
                >
                  UYGULA
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
              ref="dialog"
              v-model="dateDialog"
              :return-value.sync="dates"
              persistent
              width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="float-right mx-4 my-2" icon v-bind="attrs" v-on="on"
              >
                <v-icon large> mdi-calendar</v-icon>
              </v-btn
              >
            </template>
            <v-card>
              <v-date-picker
                  first-day-of-week="1"
                  v-model="dates"
                  scrollable
                  range
                  color="green lighten-1"
                  header-color="primary"
                  locale="tr"
                  min="2021-09-13"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dateDialog = false"> İPTAL</v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="
                    getData()
                    $refs.dialog.save(dates)
                  "
                >
                  ARA
                </v-btn>
                <v-spacer></v-spacer>
              </v-date-picker>
              <v-radio-group class="mx-3" v-model="isCheckDate" column>
                <v-radio label="Son Kontrol Tarihine Göre" :value="true"></v-radio>
                <v-radio label="Ödev Verilme Tarihine Göre" :value="false"></v-radio>
              </v-radio-group>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <div class="card-body p-2">
        <div class="text-center" v-if="lessonIds.length == 0">Filtrede ders seçiniz.</div>
        <div v-else-if="!loading">
          <div
              v-if="
              lessonIds.length > 0 || (this.dates[0] && this.dates[1]) || hwAttendanceStatusId > 0
            "
              class="container"
          >
            <h4>
              Seçili Filtreler:
              <span
                  class="mx-1 label label-lg label-light-primary label-inline"
                  v-if="lessonIds.length > 0"
              >Ders</span
              >
              <span
                  class="mx-1 label label-lg label-light-primary label-inline"
                  v-if="lessonUnitIds.length > 0"
              >Ünite</span
              >
              <span
                  class="mx-1 label label-lg label-light-primary label-inline"
                  v-if="subjectIds.length > 0"
              >Konu</span
              >
              <span
                  class="mx-1 label label-lg label-light-primary label-inline"
                  v-if="childSubjectIds.length > 0"
              >Alt konu</span
              >
              <span
                  class="mx-1 label label-lg label-light-primary label-inline"
                  v-if="this.dates[0] || this.dates[1]"
              >Tarih</span
              >
              <span
                  class="mx-1 label label-lg label-light-primary label-inline"
                  v-if="hwAttendanceStatusId > 0"
              >Ödev Durumu</span
              >
            </h4>
          </div>
          <div class="table-responsive" v-for="course in allData" :key="course.key">
            <h4 class="text-center mt-5">{{ course.key }}</h4>
            <table class="table table-bordered table-vertical-center table-sm rounded">
              <thead class="thead-light">
              <tr>
                <template v-for="(item, i) in headers">
                  <th class="text-center col-3" v-bind:key="i">{{ item }}</th>
                </template>
              </tr>
              </thead>
              <tbody>
              <tr v-if="course.values.length <= 0" class="text-center">
                Ödev Bulunamadı...
              </tr>
              <template v-else v-for="(homework, i) in course.values">
                <tr v-bind:key="i">
                  <td class="p-2 text-left col-3">
                    {{ homework.LessonUnitName }}
                  </td>
                  <td class="p-2 text-left col-3">
                    {{ homework.SubjectName }}
                  </td>
                  <td class="p-2 text-center col-3">
                    {{ homework.CompletedQuestionCount }}
                  </td>
                  <td class="p-2 text-center col-3">
                    {{ homework.NotCompletedQuestionCount }}
                  </td>
                  <td class="p-2 text-center col-3">
                    {{ homework.QuestionCount }}
                  </td>
                  <td class="p-2 text-center col-3">
                    {{ homework.CorrectCount }}
                  </td>
                  <td class="p-2 text-center col-3">
                    {{ homework.WrongCount }}
                  </td>
                  <td class="p-2 text-center col-3">
                    {{ homework.EmptyCount }}
                  </td>
                  <td class="p-2 text-center col-3">
                    %{{ homework.SCY > 0 ? homework.SCY.toFixed(2) : 0 }}
                  </td>
                </tr>
              </template>
              <tr>
                <td class="p-2 text-center col-3"></td>
                <td class="p-2 text-right col-3"></td>
                <td class="p-2 text-center col-3">
                  <strong> {{
                      course.values
                          .map((item) => item.CompletedQuestionCount)
                          .reduce((prev, curr) => prev + curr, 0)
                    }}</strong>
                </td>
                <td class="p-2 text-center col-3">
                  <strong> {{
                      course.values
                          .map((item) => item.NotCompletedQuestionCount)
                          .reduce((prev, curr) => prev + curr, 0)
                    }}</strong>
                </td>
                <td class="p-2 text-center col-3">
                  <strong> {{
                      course.values
                          .map((item) => item.QuestionCount)
                          .reduce((prev, curr) => prev + curr, 0)
                    }}</strong>
                </td>
                <td class="p-2 text-center col-3">
                  <strong>{{ totalTrueAnswerCount(course.values) }}</strong>
                </td>
                <td class="p-2 text-center col-3">
                  <strong>{{ totalWrongAnswerCount(course.values) }}</strong>
                </td>
                <td class="p-2 text-center col-3">
                  <strong>{{ totalEmptyAnswerCount(course.values) }}</strong>
                </td>
                <td class="p-2 text-center col-3">
                  <strong>%{{ correctAnswerRate(course.values) }}</strong>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else>
          <div class="text-center mt-5">
            <div class="spinner-border"></div>
          </div>
        </div>
        <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackbarColor" rounded="pill">
          {{ snackbarText }}
        </v-snackbar>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service.js'

export default {
  name: 'odev-karnesi',
  data() {
    return {
      snackbar: false,
      snackbarColor: 'success',
      snackbarText: '',
      timeout: 2000,
      allData: [],
      loading: true,
      headers: [
        'Ünite',
        'Konu',
        'Yapılan Soru Sayısı',
        'Yapılmayan Soru Sayısı',
        'Toplam Soru Sayısı',
        'D',
        'Y',
        'B',
        'SÇY'
      ],
      dateDialog: false,
      dates: [null, null],
      isCheckDate: true,
      filterDialog: false,
      lessons: [],
      lessonIds: [],
      lessonUnitIds: [],
      lessonUnits: [],
      subjects: [],
      subjectIds: [],
      childSubjectIds: [],
      childSubjects: [],
      hwAttendanceStatusId: 0,
      hwAttendanceStatuses: [
        {Id: 0, Name: 'Tüm Ödevler'},
        {Id: 1, Name: 'Yapılanlar'},
        {Id: 2, Name: 'Yapılmayanlar'}
      ],
      sourceTestAchievements: [],
      options: {
        sortBy: ['LessonUnitId']
      }
    }
  },
  mounted() {
    this.getLessonsFromApi()
  },
  methods: {
    totalQuestionCount(homeworks) {
      let totalQuestionCount = 0
      for (let index = 0; index < homeworks.length; index++) {
        const element = homeworks[index]
        totalQuestionCount = totalQuestionCount += element.QuestionCount
      }
      return totalQuestionCount
    },
    totalTrueAnswerCount(homeworks) {
      let totalTrueAnswerCount = 0
      for (let index = 0; index < homeworks.length; index++) {
        const element = homeworks[index]
        totalTrueAnswerCount = totalTrueAnswerCount += element.CorrectCount
      }
      return totalTrueAnswerCount
    },
    totalWrongAnswerCount(homeworks) {
      let totalWrongAnswerCount = 0
      for (let index = 0; index < homeworks.length; index++) {
        const element = homeworks[index]
        totalWrongAnswerCount = totalWrongAnswerCount += element.WrongCount
      }
      return totalWrongAnswerCount
    },
    totalEmptyAnswerCount(homeworks) {
      let totalEmptyAnswerCount = 0
      for (let index = 0; index < homeworks.length; index++) {
        const element = homeworks[index]
        if (element.MarkingCount > 0)
          totalEmptyAnswerCount = totalEmptyAnswerCount += element.EmptyCount
      }
      return totalEmptyAnswerCount
    },
    correctAnswerRate(homeworks) {
      let totalCorrectPercentage = 0.00
      let hwCount = 0

      for (let index = 0; index < homeworks.length; index++) {
        const element = homeworks[index]
        if (element.CorrectCount > 0 || element.WrongCount > 0) {
          totalCorrectPercentage = totalCorrectPercentage += element.SCY
          hwCount++
        }
      }
      return hwCount > 0 ? (totalCorrectPercentage / hwCount).toFixed(2) : 0
    },
    getData() {
      // TODO:: yapılan/yapılmayanları hwAttendanceStatusId'e göre filtrele

      // TODO:: filtreyi sıfırladıktan sonra veriler de sıfırlansın. uygula demeden filtreyi kapatınca eski çekilen veri görünüyor
      if (this.lessonIds.length == 0) {
        this.snackbarText = 'Filtrede ders seçiniz'
        this.snackbarColor = 'red'
        this.snackbar = true
        return
      }
      this.loading = true
      if (this.dates[0] && this.dates[1]) {
        var g1 = new Date(this.dates[0])
        var g2 = new Date(this.dates[1])
        if (g2.getTime() < g1.getTime()) {
          var tmpDate = this.dates[0]
          this.dates[0] = this.dates[1]
          this.dates[1] = tmpDate
        }
      }
      var model = {
        StartDate: this.dates[0],
        EndDate: this.dates[1],
        LessonUnitIds: this.lessonUnitIds,
        ParentSubjectIds: this.subjectIds,
        SubjectIds: this.childSubjectIds,
        LessonIds: this.lessonIds
      }
      ApiService.setHeader()
      ApiService.post('api/Homework/reports/StudentLessonUnitSubjectReportForUser', {
        sortBy: this.options.sortBy,
        descending: this.options.sortDesc,
        rowsPerPage: -1,
        page: 1,
        Query: model
      })
          .then((data) => {
            this.allData = this.groupByArray(data.data.Results, 'CourseName')
            this.loading = false
            this.filterDialog = false
          })
          .catch(({response}) => {
            ApiService.showError(response)
            this.loading = false
          })
    },

    groupByArray(xs, key) {
      return xs.reduce(function (rv, x) {
        let v = key instanceof Function ? key(x) : x[key]
        let el = rv.find((r) => r && r.key === v)
        if (el) {
          el.values.push(x)
        } else {
          rv.push({key: v, values: [x]})
        }
        return rv
      }, [])
    },

    getLessonsFromApi() {
      ApiService.setHeader()
      ApiService.post('api/Lesson', {
        sortBy: ['Name'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        query: {IsStudent: true}
      })
          .then((data) => {
            this.lessons = data.data.Results
            this.lessonIds = this.lessons.map((item) => item.Id)
            this.getData()
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },
    
    getLessonUnitsFromApi() {
      ApiService.setHeader()
      ApiService.post('api/LessonUnit', {
        sortBy: ['Name'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        query: {
          LessonIds: this.lessonIds
        }
      })
          .then((data) => {
            this.lessonUnits = data.data.Results
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },

    getSourceTestAchievementsFromApi() {
      ApiService.setHeader()
      ApiService.post('api/SourceTestAchievement', {
        sortBy: ['Id'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        query: {
          LessonIds: this.lessonIds
        }
      })
          .then((data) => {
            this.sourceTestAchievements = data.data.Results
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },

    getSubjectsFromApi() {
      ApiService.setHeader()
      ApiService.post('api/Subject', {
        sortBy: ['Name'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        query: {
          LessonUnitIds: this.lessonUnitIds,
          ForStudent: true
        }
      })
          .then((data) => {
            this.subjects = data.data.Results
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },
    getChildSubjectsFromApi() {
      ApiService.setHeader()
      ApiService.post('api/Subject', {
        sortBy: ['Name'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        query: {
          ParentSubjectIds: this.subjectIds
        }
      })
          .then((data) => {
            this.childSubjects = data.data.Results
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },

    cleanFilter() {
      this.lessonIds = []
      this.lessonUnitIds = []
      this.subjectIds = []
      this.childSubjectIds = []
    },

    lessonOnChange() {
      this.getSourceTestAchievementsFromApi()
    },
    lessonUnitOnChange() {
      ApiService.post('api/SourceTestAchievement', {
        sortBy: ['Id'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        query: {
          LessonIds: this.lessonIds,
          LessonUnitIds: this.lessonUnitIds
        }
      })
          .then((data) => {
            this.subjects = data.data.Results
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },
    subjectOnChange() {
      this.getChildSubjectsFromApi()
    }
  }
}
</script>
