var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card card-custom card-stretch gutter-b" }, [
    _c("div", { staticClass: "card card-custom card-stretch gutter-b" }, [
      _c("div", { staticClass: "card-header border-0 p-2" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "card-toolbar" },
          [
            _c(
              "v-dialog",
              {
                attrs: { persistent: "", "max-width": "300px" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              { staticClass: "mx-2", attrs: { small: "" } },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [_vm._v(" FİLTRE")]
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.filterDialog,
                  callback: function ($$v) {
                    _vm.filterDialog = $$v
                  },
                  expression: "filterDialog",
                },
              },
              [
                _c(
                  "v-card",
                  [
                    _c(
                      "v-toolbar",
                      { attrs: { flat: "" } },
                      [
                        _c(
                          "v-row",
                          { staticClass: "justify-content-between" },
                          [
                            _c(
                              "v-col",
                              { staticClass: "col-4" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    on: {
                                      click: function ($event) {
                                        _vm.filterDialog = false
                                      },
                                    },
                                  },
                                  [_vm._v("mdi-close")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "col-4 d-flex justify-content-center",
                              },
                              [_c("h5", [_vm._v("FİLTRELE")])]
                            ),
                            _c(
                              "v-col",
                              { staticClass: "col-4 text-right" },
                              [
                                _c(
                                  "v-icon",
                                  { on: { click: _vm.cleanFilter } },
                                  [_vm._v("mdi-refresh")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-text",
                      [
                        _c("v-autocomplete", {
                          attrs: {
                            "item-text": (item) => item.Name,
                            "item-value": (item) => item.Id,
                            items: _vm.lessons,
                            label: "Ders",
                            "single-line": "",
                            "hide-details": "",
                            clearable: "",
                            multiple: "",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.lessonOnChange()
                            },
                          },
                          model: {
                            value: _vm.lessonIds,
                            callback: function ($$v) {
                              _vm.lessonIds = $$v
                            },
                            expression: "lessonIds",
                          },
                        }),
                        _c("v-autocomplete", {
                          attrs: {
                            "item-text": (item) => item.LessonUnitName,
                            "item-value": (item) => item.LessonUnitId,
                            items: _vm.sourceTestAchievements,
                            "single-line": "",
                            "hide-details": "",
                            clearable: "",
                            multiple: "",
                            disabled: _vm.lessonIds.length <= 0,
                            label:
                              _vm.lessonIds.length > 0
                                ? "Ünite"
                                : "Ünite (ders seçiniz)",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.lessonUnitOnChange()
                            },
                          },
                          model: {
                            value: _vm.lessonUnitIds,
                            callback: function ($$v) {
                              _vm.lessonUnitIds = $$v
                            },
                            expression: "lessonUnitIds",
                          },
                        }),
                        _c("v-autocomplete", {
                          attrs: {
                            "item-text": (item) => item.ParentSubjectName,
                            "item-value": (item) => item.ParentSubjectId,
                            items: _vm.subjects,
                            "single-line": "",
                            "hide-details": "",
                            clearable: "",
                            multiple: "",
                            disabled: _vm.lessonUnitIds.length <= 0,
                            label:
                              _vm.lessonUnitIds.length > 0
                                ? "Konu"
                                : "Konu (ünite seçiniz)",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.subjectOnChange()
                            },
                          },
                          model: {
                            value: _vm.subjectIds,
                            callback: function ($$v) {
                              _vm.subjectIds = $$v
                            },
                            expression: "subjectIds",
                          },
                        }),
                        _c("v-autocomplete", {
                          attrs: {
                            "item-text": (item) => item.Name,
                            "item-value": (item) => item.Id,
                            items: _vm.childSubjects,
                            "single-line": "",
                            "hide-details": "",
                            clearable: "",
                            multiple: "",
                            disabled: _vm.subjectIds.length <= 0,
                            label:
                              _vm.subjectIds.length > 0
                                ? "Alt konu"
                                : "Alt konu (Konu seçiniz)",
                          },
                          model: {
                            value: _vm.childSubjectIds,
                            callback: function ($$v) {
                              _vm.childSubjectIds = $$v
                            },
                            expression: "childSubjectIds",
                          },
                        }),
                        _c("v-autocomplete", {
                          attrs: {
                            "item-text": (item) => item.Name,
                            "item-value": (item) => item.Id,
                            items: _vm.hwAttendanceStatuses,
                            label: "Ödev Durumu",
                            "single-line": "",
                            "hide-details": "",
                          },
                          model: {
                            value: _vm.hwAttendanceStatusId,
                            callback: function ($$v) {
                              _vm.hwAttendanceStatusId = $$v
                            },
                            expression: "hwAttendanceStatusId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "justify-content-center" },
                      [
                        _c(
                          "v-btn",
                          {
                            on: {
                              click: function ($event) {
                                _vm.filterDialog = false
                                _vm.getData()
                              },
                            },
                          },
                          [_vm._v(" UYGULA ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                ref: "dialog",
                attrs: {
                  "return-value": _vm.dates,
                  persistent: "",
                  width: "290px",
                },
                on: {
                  "update:returnValue": function ($event) {
                    _vm.dates = $event
                  },
                  "update:return-value": function ($event) {
                    _vm.dates = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "float-right mx-4 my-2",
                                attrs: { icon: "" },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c("v-icon", { attrs: { large: "" } }, [
                              _vm._v(" mdi-calendar"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.dateDialog,
                  callback: function ($$v) {
                    _vm.dateDialog = $$v
                  },
                  expression: "dateDialog",
                },
              },
              [
                _c(
                  "v-card",
                  [
                    _c(
                      "v-date-picker",
                      {
                        attrs: {
                          "first-day-of-week": "1",
                          scrollable: "",
                          range: "",
                          color: "green lighten-1",
                          "header-color": "primary",
                          locale: "tr",
                          min: "2021-09-13",
                        },
                        model: {
                          value: _vm.dates,
                          callback: function ($$v) {
                            _vm.dates = $$v
                          },
                          expression: "dates",
                        },
                      },
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { text: "", color: "primary" },
                            on: {
                              click: function ($event) {
                                _vm.dateDialog = false
                              },
                            },
                          },
                          [_vm._v(" İPTAL")]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: { text: "", color: "primary" },
                            on: {
                              click: function ($event) {
                                _vm.getData()
                                _vm.$refs.dialog.save(_vm.dates)
                              },
                            },
                          },
                          [_vm._v(" ARA ")]
                        ),
                        _c("v-spacer"),
                      ],
                      1
                    ),
                    _c(
                      "v-radio-group",
                      {
                        staticClass: "mx-3",
                        attrs: { column: "" },
                        model: {
                          value: _vm.isCheckDate,
                          callback: function ($$v) {
                            _vm.isCheckDate = $$v
                          },
                          expression: "isCheckDate",
                        },
                      },
                      [
                        _c("v-radio", {
                          attrs: {
                            label: "Son Kontrol Tarihine Göre",
                            value: true,
                          },
                        }),
                        _c("v-radio", {
                          attrs: {
                            label: "Ödev Verilme Tarihine Göre",
                            value: false,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "card-body p-2" },
        [
          _vm.lessonIds.length == 0
            ? _c("div", { staticClass: "text-center" }, [
                _vm._v("Filtrede ders seçiniz."),
              ])
            : !_vm.loading
            ? _c(
                "div",
                [
                  _vm.lessonIds.length > 0 ||
                  (this.dates[0] && this.dates[1]) ||
                  _vm.hwAttendanceStatusId > 0
                    ? _c("div", { staticClass: "container" }, [
                        _c("h4", [
                          _vm._v(" Seçili Filtreler: "),
                          _vm.lessonIds.length > 0
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "mx-1 label label-lg label-light-primary label-inline",
                                },
                                [_vm._v("Ders")]
                              )
                            : _vm._e(),
                          _vm.lessonUnitIds.length > 0
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "mx-1 label label-lg label-light-primary label-inline",
                                },
                                [_vm._v("Ünite")]
                              )
                            : _vm._e(),
                          _vm.subjectIds.length > 0
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "mx-1 label label-lg label-light-primary label-inline",
                                },
                                [_vm._v("Konu")]
                              )
                            : _vm._e(),
                          _vm.childSubjectIds.length > 0
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "mx-1 label label-lg label-light-primary label-inline",
                                },
                                [_vm._v("Alt konu")]
                              )
                            : _vm._e(),
                          this.dates[0] || this.dates[1]
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "mx-1 label label-lg label-light-primary label-inline",
                                },
                                [_vm._v("Tarih")]
                              )
                            : _vm._e(),
                          _vm.hwAttendanceStatusId > 0
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "mx-1 label label-lg label-light-primary label-inline",
                                },
                                [_vm._v("Ödev Durumu")]
                              )
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.allData, function (course) {
                    return _c(
                      "div",
                      { key: course.key, staticClass: "table-responsive" },
                      [
                        _c("h4", { staticClass: "text-center mt-5" }, [
                          _vm._v(_vm._s(course.key)),
                        ]),
                        _c(
                          "table",
                          {
                            staticClass:
                              "table table-bordered table-vertical-center table-sm rounded",
                          },
                          [
                            _c("thead", { staticClass: "thead-light" }, [
                              _c(
                                "tr",
                                [
                                  _vm._l(_vm.headers, function (item, i) {
                                    return [
                                      _c(
                                        "th",
                                        {
                                          key: i,
                                          staticClass: "text-center col-3",
                                        },
                                        [_vm._v(_vm._s(item))]
                                      ),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ]),
                            _c(
                              "tbody",
                              [
                                course.values.length <= 0
                                  ? _c("tr", { staticClass: "text-center" }, [
                                      _vm._v(" Ödev Bulunamadı... "),
                                    ])
                                  : _vm._l(
                                      course.values,
                                      function (homework, i) {
                                        return [
                                          _c("tr", { key: i }, [
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "p-2 text-left col-3",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      homework.LessonUnitName
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "p-2 text-left col-3",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      homework.SubjectName
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "p-2 text-center col-3",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      homework.CompletedQuestionCount
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "p-2 text-center col-3",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      homework.NotCompletedQuestionCount
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "p-2 text-center col-3",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      homework.QuestionCount
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "p-2 text-center col-3",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      homework.CorrectCount
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "p-2 text-center col-3",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      homework.WrongCount
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "p-2 text-center col-3",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      homework.EmptyCount
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "p-2 text-center col-3",
                                              },
                                              [
                                                _vm._v(
                                                  " %" +
                                                    _vm._s(
                                                      homework.SCY > 0
                                                        ? homework.SCY.toFixed(
                                                            2
                                                          )
                                                        : 0
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      }
                                    ),
                                _c("tr", [
                                  _c("td", {
                                    staticClass: "p-2 text-center col-3",
                                  }),
                                  _c("td", {
                                    staticClass: "p-2 text-right col-3",
                                  }),
                                  _c(
                                    "td",
                                    { staticClass: "p-2 text-center col-3" },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              course.values
                                                .map(
                                                  (item) =>
                                                    item.CompletedQuestionCount
                                                )
                                                .reduce(
                                                  (prev, curr) => prev + curr,
                                                  0
                                                )
                                            )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "p-2 text-center col-3" },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              course.values
                                                .map(
                                                  (item) =>
                                                    item.NotCompletedQuestionCount
                                                )
                                                .reduce(
                                                  (prev, curr) => prev + curr,
                                                  0
                                                )
                                            )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "p-2 text-center col-3" },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              course.values
                                                .map(
                                                  (item) => item.QuestionCount
                                                )
                                                .reduce(
                                                  (prev, curr) => prev + curr,
                                                  0
                                                )
                                            )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "p-2 text-center col-3" },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.totalTrueAnswerCount(
                                              course.values
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "p-2 text-center col-3" },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.totalWrongAnswerCount(
                                              course.values
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "p-2 text-center col-3" },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.totalEmptyAnswerCount(
                                              course.values
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "p-2 text-center col-3" },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          "%" +
                                            _vm._s(
                                              _vm.correctAnswerRate(
                                                course.values
                                              )
                                            )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ],
                              2
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                ],
                2
              )
            : _c("div", [_vm._m(1)]),
          _c(
            "v-snackbar",
            {
              attrs: {
                timeout: _vm.timeout,
                color: _vm.snackbarColor,
                rounded: "pill",
              },
              model: {
                value: _vm.snackbar,
                callback: function ($$v) {
                  _vm.snackbar = $$v
                },
                expression: "snackbar",
              },
            },
            [_vm._v(" " + _vm._s(_vm.snackbarText) + " ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "card-title align-items-start pr-0 mr-0" }, [
      _c(
        "span",
        {
          staticClass:
            "card-label font-weight-bolder text-dark pr-0 mr-0 mx-4 my-4",
        },
        [_vm._v(" ÖDEV ÜNİTE-KONU RAPORU ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center mt-5" }, [
      _c("div", { staticClass: "spinner-border" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }